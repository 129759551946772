(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/retail-utils/handlebars-helpers/assets/javascripts/handlebarshelpers.js') >= 0) return;  svs.modules.push('/components/retail-utils/handlebars-helpers/assets/javascripts/handlebarshelpers.js');
"use strict";


function registerHelpers(hbs) {
  hbs.registerHelper('printUserAlias', options => {
    var _svs$core$session;
    if (typeof exports === 'object') {
      var _options$data$root$se;
      return (_options$data$root$se = options.data.root.session) === null || _options$data$root$se === void 0 ? void 0 : _options$data$root$se.get('userAlias');
    }
    return (_svs$core$session = svs.core.session) === null || _svs$core$session === void 0 || (_svs$core$session = _svs$core$session.data) === null || _svs$core$session === void 0 || (_svs$core$session = _svs$core$session.userSession) === null || _svs$core$session === void 0 || (_svs$core$session = _svs$core$session.data) === null || _svs$core$session === void 0 ? void 0 : _svs$core$session.userAlias;
  });
}
if (typeof exports === 'object') {
  registerHelpers(require('hbs'));
} else {
  registerHelpers(Handlebars);
}

 })(window);